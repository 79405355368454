// components/ui/Button.tsx
import React from 'react';
import Link from 'next/link';

type ButtonProps = {
  primary?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorButtonProps = {
  primary?: boolean;
  children: React.ReactNode;
  href: string;
  className?: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const baseStyles = 'px-4 py-2 rounded-md transition-colors duration-300';
const primaryStyles = 'bg-[#8272FA] text-white hover:bg-[#6a5ad7]';
const secondaryStyles = 'bg-[#1F2937] text-white hover:bg-gray-700';

export const Button: React.FC<ButtonProps> = ({
                                                primary = true,
                                                children,
                                                onClick,
                                                className = '',
                                                ...props
                                              }) => {
  const combinedClasses = `${baseStyles} ${
    primary ? primaryStyles : secondaryStyles
  } ${className}`;

  return (
    <button className={combinedClasses} onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export const AnchorButton: React.FC<AnchorButtonProps> = ({
                                                            primary = true,
                                                            children,
                                                            href,
                                                            className = '',
                                                            ...props
                                                          }) => {
  const combinedClasses = `${baseStyles} ${
    primary ? primaryStyles : secondaryStyles
  } ${className}`;

  return (
    <Link href={href} className={combinedClasses} {...props}>
      {children}
    </Link>
  );
};
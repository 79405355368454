// app/components/DevLogoutLink.tsx
'use client';

import Link from 'next/link';

export default function DevLogoutLink() {
  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    console.log('Logout');
    // Clear tokens from local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    // Clear tokens from cookies (if used)
    document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    console.log('Tokens cleared. User logged out.');

    // Reload the page or redirect as needed
    window.location.href = '/auth/signin';
  };

  return <Link href="" onClick={handleLogout}>Dev Logout1</Link>;
}
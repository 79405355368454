'use client';

import { useEffect } from 'react';

export default function ScrollbarGutter() {
  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.style.overflowY = 'scroll';

    return () => {
      document.body.style.paddingRight = '';
      document.body.style.overflowY = '';
    };
  }, []);

  return null;
}
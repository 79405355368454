'use client';

import { useEffect, useState } from "react";
import Logo from "../logo";
import { AnchorButton } from '@/components/ui/button';
import { useSession } from "next-auth/react";
import { useRouter } from 'next/navigation';
import useSWR from 'swr';
import { Session } from "next-auth";
import DropdownProfile from "@/components/ui/dashboard/dropdown-profile";
import { signOut } from 'next-auth/react'
import SearchModal from "@/components/ui/search-modal";

interface UserData {
  id: string;
  picture?: string;
  given_name?: string;
  family_name?: string;
  role?: string;
}

const useUserData = (session: Session | null) => {
  const fetcher = (url: string): Promise<UserData> => fetch(url).then((res) => res.json());
  const userId = session?.user?.id;
  const userRole = session?.user?.role;

  const { data, error, mutate } = useSWR<UserData>(
    userId ? `/api/${userRole?.toLowerCase()}/${userId}` : null,
    fetcher
  );

  return {
    userData: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
};

export default function Header() {
  const [top, setTop] = useState<boolean>(true);
  const { data: session } = useSession();
  const router = useRouter();
  const { userData, isLoading, isError, mutate: mutateUserData } = useUserData(session);
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    if(session?.user && !session.user.role) {
      handleIncompleteSession()
    }

    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top, session?.user]);

  const handleIncompleteSession = async () => {
    await signOut({ redirect: false });
  };

  return (
    <header className="fixed top-2 z-30 w-full md:top-6" style={{paddingRight: 'var(--scrollbar-width, 0px)'}}>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div
          className="relative flex h-14 items-center justify-between gap-3 rounded-2xl bg-white/90 px-3 shadow-lg shadow-black/[0.03] backdrop-blur-sm before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(theme(colors.gray.100),theme(colors.gray.200))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)]">
          {/* Site branding */}
          <div className="flex flex-1 items-center">
            <Logo/>
          </div>

          {/* Desktop sign in links */}
          <ul className="flex items-center justify-end gap-3">
            {/* Search button */}
            <li className="relative">
              <div>
                <button
                  className={`w-8 h-8 flex items-center justify-center hover:bg-gray-100 lg:hover:bg-gray-200 dark:hover:bg-gray-700/50 dark:lg:hover:bg-gray-800 rounded-full ml-3 ${searchModalOpen && 'bg-gray-200 dark:bg-gray-800'}`}
                  onClick={() => {
                    setSearchModalOpen(true)
                  }}
                >
                  <span className="sr-only">Suche</span>
                  <svg
                    className="fill-current text-gray-500/80 dark:text-gray-400/80"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7ZM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5Z"/>
                    <path
                      d="m13.314 11.9 2.393 2.393a.999.999 0 1 1-1.414 1.414L11.9 13.314a8.019 8.019 0 0 0 1.414-1.414Z"/>
                  </svg>
                </button>
                <SearchModal isOpen={searchModalOpen} setIsOpen={setSearchModalOpen}/>
              </div>
            </li>
            {session ? (
              <li>
                {isLoading ? (
                  <div className="w-8 h-8 rounded-full bg-gray-200 animate-pulse"></div>
                ) : isError ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-8 h-8 text-gray-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                ) : (
                  <DropdownProfile
                    align="right"
                    picture={userData?.picture || session.user.picture || ""}
                    givenName={userData?.given_name || session.user.given_name || ""}
                    familyName={userData?.family_name || session.user.family_name || ""}
                    role={userData?.role || session.user.role || ""}
                  />
                )}
              </li>
            ) : (
              <>
                <li>
                  <AnchorButton href="/auth/signin" primary className="w-full">
                    Login
                  </AnchorButton>
                </li>
                <li>
                  <AnchorButton href="/auth/register" primary={false} className="w-full">
                    Register
                  </AnchorButton>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
}